import React, { useState } from "react"
import Feature from "../components/Feature"
import FeatureList from "../components/FeatureList"
import Page from "../components/Page"
import ProjectList from "../components/ProjectList"
import Layout from "../components/Layout"
import HeroTitle from "../components/HeroTitle"
import fair_prices from "../images/fair_prices.png"
import modern_technologies from "../images/modern_technologies.png"
import social_software from "../images/social_software.png"

import { view, video, title } from "../styles/index.module.scss"
import Seo from "../SEO"
import { useTranslation } from "react-i18next"
import ContactPersonList from "../components/ContactPersonList"
import PressAndSupporterList from "../components/PressAndSupporterList"
import CookieRequest from "../components/CookieRequest"
import Cookies from "universal-cookie"

import {
  apply_form,
  container,
  containerScholarship,
  description_container,
  container_title,
} from "../styles/index-scholarship.module.scss"

const LandingPage = () => {
  const cookies = new Cookies()
  const [visible, setVisible] = useState(false)
  const shouldCookieRequestBeDisplayed = () => {
    return cookies.get("youtube-video") === "true" || visible
  }
  const [t] = useTranslation()
  return (
    <Page>
      <Seo useSchema={true} />
      <Layout>
        <div className={view}>
          <HeroTitle text={t("We offer social software at a fair price")} />
        </div>
        <FeatureList>
          <Feature
            imageFile={modern_technologies}
            titleString={t("Modern Technologies")}
            textString={t("Modern Technologies Paragraph")}
          />
          <Feature
            imageFile={social_software}
            titleString={t("Social Software development")}
            textString={t("Social Software development Paragraph")}
          />
          <Feature
            imageFile={fair_prices}
            titleString={t("Fair prices")}
            textString={t("Fair prices Paragraph")}
          />
        </FeatureList>

        <div className={containerScholarship}>
          <div className={description_container}>
            <h2 className={container_title}>Apply for our Coding Scholarship until September 22nd 2024</h2>
            <a className={apply_form} target="_blank" href="https://links.debacode.de/scholarship-application">
              {t("Scholarship Application Form")}
            </a>        
          </div>
        </div>

        <div className={video}>
          {shouldCookieRequestBeDisplayed() && (
            <iframe
              loading="lazy"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/eqkPJKQkfMY"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Wer? Wie? Was? ist DeBaCode, das Video"
            />
          )}
          {!shouldCookieRequestBeDisplayed() && (
            <CookieRequest
              identifier="youtube-request"
              setVisible={setVisible}
            />
          )}
        </div>
        <h2 className={title}>{t("Our projects")}</h2>
        <ProjectList />
        <ContactPersonList />
        <PressAndSupporterList />
      </Layout>
    </Page>
  )
}

export default LandingPage
